<template>
  <div>
    <b-img-lazy :src="data.image_qrcode" fluid></b-img-lazy>
    <p>
      <b-button @click="downloadImage({
      url: data.image_qrcode,
      label: data.token
      })" variant="primary">Download
      </b-button>
    </p>
    <p>ID: <strong>{{ data.token }}</strong></p>
    <p>Merchant: <strong>{{ data.customer }}</strong></p>
    <p>Order ID: <strong>{{ data.orderid }}</strong></p>
    <p>Price: <strong>{{ convertPrice }} THB</strong></p>
  </div>
</template>

<script>
export default {
  name: "DetailQrcode",
  props: ['data'],
  computed: {
    convertPrice() {
      return this.data && this.data.price && !isNaN(this.data.price) ? this.data.price.toFixed(2) : 0.00
    },
  },
  methods: {
    async downloadImage({url, label}) {
      console.log(url, label);
      const response = await this.axios.get(url, {responseType: "blob"});
      const blob = new Blob([response.data], {type: "application/pdf"});
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${label}.png`;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  }
}
</script>

<style scoped>

</style>
